import React from "react";
import { Link } from "gatsby";
import Accordion from "../accordion/accordion";
import "./_services.scss";

const Services = () => (
  <div className="services-component red-accordion">
    <Accordion>
      <div label="Growth Department" subtitle="">
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">Advertising Campaigns</div>
          <Link className="btn btn-dark" to="/services/advertising">
            LEARN MORE
          </Link>
        </div>
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">Fractional CMO</div>
          <Link className="btn btn-dark" to="/services/cmo">
            LEARN MORE
          </Link>
        </div>
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">Martech Management</div>
          <Link className="btn btn-dark" to="/services/support">
            LEARN MORE
          </Link>
        </div>
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">Creator Campaigns</div>
          <Link className="btn btn-dark" to="/services/content">
            LEARN MORE
          </Link>
        </div>
      </div>

      <div label="Launch Department" subtitle="">
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">Websites</div>
          <Link className="btn btn-dark" to="/services/websites">
            LEARN MORE
          </Link>
        </div>
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">Creator Campaigns</div>
          <Link className="btn btn-dark" to="/services/content">
            LEARN MORE
          </Link>
        </div>
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">Fractional CMO</div>
          <Link className="btn btn-dark" to="/services/cmo">
            LEARN MORE
          </Link>
        </div>
      </div>

      <div label="Manage Department" subtitle="">
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">Martech Management</div>
          <Link className="btn btn-dark" to="/services/support">
            LEARN MORE
          </Link>
        </div>
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">
            Content Planning and Strategy
          </div>
          <Link className="btn btn-dark" to="/services/content">
            LEARN MORE
          </Link>
        </div>
        <div className="accordion-services-subitem">
          <div className="accordion-subitem-title">Fractional CMO</div>
          <Link className="btn btn-dark" to="/services/cmo">
            LEARN MORE
          </Link>
        </div>
      </div>
    </Accordion>
  </div>
);

export default Services;
