import React from "react";
import { Link } from "gatsby";
import Layout from "../components/base/layout";
import SEO from "../components/base/seo";
import Accordion from "../components/accordion/accordion";
import Services from "../components/services/services";
import "../styles/pages/_home.scss";
import happyGuy from "../images/home/TSD_Home_Page_Collage_Our_Goal.png";
import TypeAnimation from "react-type-animation";

const IndexPage = () => (
  <Layout type="template-home">
    <SEO
      title="The Simple Department | Not Your Typical Marketing Agency"
      description="Want more from your marketing without the agency headaches? Meet the Simple Department. Right-sized marketing, web, and design services to suit your needs."
    />
    <section className="section-intro">
      <div className="intro-top">
        <div className="container">
          <TypeAnimation
            cursor={false}
            repeat={Infinity}
            sequence={[
              "Design",
              4000,
              "Growth",
              4000,
              "Photography",
              4000,
              "Videography",
              4000,
              "Strategy",
              4000,
              "Marketing",
              4000,
              "Website",
              4000,
              "Advertising",
              4000,
              "Branding",
              4000,
              "Content",
              4000,
              "Creator",
              4000,
              "Sales",
              4000,
              "Creative",
              4000,
              "Martech",
              4000,
            ]}
            className={"home-moving-title"}
            wrapper="h3"
          />
          <h2>Struggles?</h2>
          <span className="intro-desc">
            <p>
              Want more from your marketing, but don’t want to deal with the
              marketing agency headaches?
            </p>
          </span>
        </div>
      </div>
      <div className="intro-bottom">
        <div className="container">
          <TypeAnimation
            cursor={false}
            repeat={Infinity}
            sequence={[
              "Design struggles",
              2900,
              "Growth struggles",
              2650,
              "Photography struggles",
              2700,
              "Videography struggles",
              2900,
              "Strategy struggles",
              2900,
              "Marketing struggles",
              2700,
              "Website struggles",
              2900,
              "Advertising struggles",
              2750,
              "Branding struggles",
              2750,
              "Content struggles",
              2850,
              "Creator struggles",
              2700,
              "Sales struggles",
              2750,
              "Creative struggles",
              2800,
              "Martech struggles",
              3000,
            ]}
            className={"home-moving-title"}
            wrapper="h3"
          />
          <h2>Solved.</h2>
          <span className="intro-desc">
            <p>Marketing advice, services and help right-sized just for you.</p>
          </span>
        </div>
      </div>
    </section>
    <section className="section-what-we-do" id="section-what-we-do">
      <div className="home-section1 container">
        <img src={happyGuy} alt="" />
        <div className="what-we-do-desc">
          <p className="small-padding text-blue">
            <strong>Our goal is simple.</strong>
          </p>
          <p className="small-padding">
            We want to work with smart people who are building cool brands,
            making awesome products and delivering amazing services.
          </p>
          <p>
            But agency life complicates things. There is so much overhead, so
            many unnecessary processes, pointless ego and noise that gets in the
            way of being able to deliver great work that clients want and need.
            That’s why we started to rebel and create an anti-agency to free us
            to be able to do great work, plain and simple.
          </p>
        </div>
      </div>
      <div className="container">
        <h2>
          <span className="text-red">What</span> can we do for you?
        </h2>
        <div className="what-we-do-desc">
          <p className="small-padding">
            Because we are a fully stacked team of creatives, creators,
            strategists, developers and marketing leaders – we have organized
            everything we do, into 3 simple departments – each designed to meet
            you where you are in your business.
          </p>
          <p className="small-padding">
            Sometimes you will need a lot of support and a bunch of different
            services – and there will be many times, where you just need help
            organizing your ideas, coming up with a plan and making it easier
            for you to go and execute more confidently.
          </p>
          <p className="small-padding">
            We love what we do, we just want to do more of it and spend less
            time on all the boring, back-office stuff.
          </p>
        </div>
      </div>
      <div className="container">
        <Services />
      </div>
    </section>

    <section className="section-call-out">
      <div className="container">
        <div className="call-out-wrapper">
          <h2>Welcome to The Simple Department.</h2>
          <p className="small-padding">
            We use our knowledge, experience and capabilities to help you get
            awesome marketing stuff done.
          </p>
          <p className="small-padding">That’s it. That’s all.</p>
          <p className="small-padding">
            The marketing industry and all of those gurus, ninjas and wizards
            are trying too hard to make you believe they know everything. We
            definitely don’t know everything, but we do know a lot and
            collectively we do some amazing work – and we want to do that, for
            you.
          </p>
          <p className="small-padding">
            But, we don’t want to make it unnecessarily complex and slow like
            all of those big annoying agencies and their silly overhead and
            painful processes – we think marketing relationships can and should
            be, plain and simple.
          </p>
        </div>
      </div>
    </section>
    <section className="section-what-we-do section-links">
      <div className="container">
        <div className="what-we-do-desc">
          <p>Need help planning out all of the amazing content you have?</p>
          <p className="answers-text">
            We can do that with a{" "}
            <Link to="/services/content">Strategy Session</Link>.
          </p>
          <p>Need to build a website?</p>
          <p className="answers-text">
            We definitely can do that with our{" "}
            <Link to="/services/websites">Off the Shelf Websites</Link>.
          </p>
          <p>
            Trying to figure out how to get creators on TikTok talking about
            your brand and product?
          </p>
          <p className="answers-text">
            We can connect the dots with our{" "}
            <Link to="/services/advertising">Creator Campaign</Link> product.
          </p>
          <p>Struggling to scale your business to the next level?</p>
          <p className="answers-text">
            Then a <Link to="/services/cmo">Fractional CMO</Link> is what you
            need.
          </p>
          <p>Want us to plan and schedule all of your content?</p>
          <p className="answers-text">
            We can do that with our{" "}
            <Link to="/services/content">Content Planning Services</Link>.{" "}
          </p>
        </div>
      </div>
    </section>
    <section className="section-faq">
      <div className="container">
        <h2>FAQ</h2>
        <Accordion loadmore={true}>
          <div label="Why should we hire you?">
            <p>
              We know what we are doing and our team has worked on
              brands/businesses that are pretty big and also brands/businesses
              that are still ideas on napkins. We have super talented people in
              each discipline and we properly manage your project using our big
              agency tools and processes.
            </p>
          </div>
          <div label="What industries does The Simple Department work in?">
            <p>
              Almost anything that is legal and ethical. We generally avoid
              politicians and political campaigns as most of those people are
              vapid and fake and have nothing real to say and are not trying to
              accomplish real change.
            </p>
          </div>
          <div label="What size of companies does The Simple Department work with?">
            <p>
              Any company that can afford us. You could be a solo-prenuer trying
              to build yourself the dream job, not a full company – and that is
              perfect. OR you could have a team of 25 and are looking to double
              every year for the next 5 years – and we would be excited to strap
              ourselves to that rocketship.
            </p>
          </div>
          <div label="Do you work with clients in other countries?">
            <p>
              Yep! We work with any English speaking client/brand that is
              looking to actively market in an English speaking country and
              Western culture. Other markets and cultures will take some
              specialized knowledge and resources that don’t fit our simple
              pricing model and scaling services.
            </p>
          </div>
          <div label="How much does it cost to use The Simple Department's services?">
            <p>
              All of our prices are listed for instant purchase and for price
              shopping your cousin Greg who says they know everything about
              Google.
            </p>
          </div>
          <div label="What should my marketing budget be?">
            <p>
              30% of revenue if you want to grow. 10% if you want to stay
              steady. Anywhere between if you want to test out new markets,
              ideas or products.
            </p>
          </div>

          <div
            label="How is The Simple Department able to provide fixed price services?"
            hidden="true"
          >
            <p>
              Because we don’t care if we are profitable. Our parent agency can
              deal with the finances, we just want to work on the cool projects
              that we want without any of the headache or judgement that comes
              from a finance department.
            </p>
          </div>
          <div label="How does your invoicing process work?" hidden="true">
            <p>
              You pay before we start working. It is that simple. You can try
              and charm us into how lucky we would be to work with you or gain
              experience, blah, blah, blah – but at the end of the day just pay
              us and let us get to work. Do you really think we could have grown
              for the past 10 years if we were screwing people over???
            </p>
          </div>
          <div label="Will I have to sign any ongoing contracts?" hidden="true">
            <p>
              Maybe. If your business/brand grows – our simple approach may no
              longer make sense and you may need to bring on more sophisticated
              and time intensive approaches to your growth marketing. Nothing
              lasts forever.
            </p>
          </div>
          <div label="Do I have to commit to a minimum budget?" hidden="true">
            <p>
              Nope. You have already paid us before we start, so we are going to
              spend all of that money to get you where you need to go.
            </p>
          </div>
          <div label="What if I want to cancel services?" hidden="true">
            <p>
              Just stop working with us. You have already paid, so if you don’t
              want to continue just let us know where to send files or to hand
              things over to.
            </p>
          </div>
          <div label="How quickly can we get started?" hidden="true">
            <p>
              As soon as you checkout with your product, we get rolling and you
              will start seeing emails, alerts, and invites to our project
              management platform.
            </p>
          </div>
        </Accordion>
      </div>
    </section>
    <section className="section-what-we-do section-where-start">
      <div className="container">
        <div className="what-we-do-desc">
          <h3>Not sure where to start?</h3>
          <p className="small-padding text-blue">
            Well, do any of the questions/statements below match where you feel
            stuck right now?
          </p>
          <p className="question-text">
            I have a bunch of content, but I am not sure where, how or why we
            should publish it….
          </p>
          <p className="answers-text">
            We would <Link to="/services/content/">point you here</Link> as the
            best place to start.
          </p>
          <p className="question-text">
            I have a really basic website, but our business has grown a bit and
            we want to create something that works and looks better, but also
            helps our business grow….
          </p>
          <p className="answers-text">
            We would <Link to="/services/websites/">point you here</Link> as the
            best place to start.
          </p>
          <p className="question-text">
            We want to start advertising to grow our sales, but don’t know where
            to start and we are hearing that Facebook is getting too expensive –
            where else can we advertise and drive sales?
          </p>
          <p className="answers-text">
            We would <Link to="/services/advertising/">point you here</Link>{" "}
            first, but possibly here too.
          </p>
          <p className="question-text">
            We have been running ads on Facebook and Instagram, but we are not
            generating a lot of sales and I am seeing and hearing that other
            brands and stores are doing super well with their ads – what are we
            doing wrong?
          </p>
          <p className="answers-text">
            <Link to="/services/advertising/">This</Link> feels like the best
            place for you to begin.
          </p>
          <p className="question-text">
            I feel like we are wasting a lot of time and money with our ads and
            social media content, we need a strategy and a plan to do things
            smarter.
          </p>
          <p className="answers-text">
            Definitely <Link to="/services/cmo/">go here.</Link>
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
